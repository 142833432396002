<style>
img.set-height-img {
  height: 220px;
}

.full-width {
  width: 100%;
}
.h5-t-height-od {
  height: 200px;
}
.news-set-img {
  width: 100%;
  max-height: 200px;
}
.margin-right-16 {
  margin-right: 16px;
}

.absolute-sel-div {
  z-index: 999;
  position: absolute;
  color: #fff;
  bottom: 120px;
  right: 40px;
  font-size: 32px;
  display: flex;
  cursor: pointer;
}

.absolute-sel-child-div {
  margin: 0 10px;
}

.mobile-sel-div {
  bottom: 60px;
  right: 32px;
  font-size: 24px;
}
</style>

<template>
  <div class="index-container">
    <!-- i_sp -->
    <div
      v-if="'02' == state.showJqrMp4Type"
      class="i_sp"
      style="background-color: #f2f2f2"
    >
      <div class="absolute-sel-div" v-if="'Web' == state.appType">
        <div class="absolute-sel-child-div">
          <i @click="updateSelJumpType" class="fa fa-step-backward"></i>
        </div>
        <div class="absolute-sel-child-div">
          <i @click="updateSelJumpType" class="fa fa-step-forward"></i>
        </div>
      </div>

      <!-- <div class="absolute-sel-div mobile-sel-div" v-else>
        <div class="absolute-sel-child-div">
          <i @click="updateSelJumpType" class="fa fa-step-backward"></i>
        </div>
        <div class="absolute-sel-child-div">
          <i @click="updateSelJumpType" class="fa fa-step-forward"></i>
        </div>
      </div> -->
      <video
        :poster="state.mp4coverImg2"
        controls
        name="media"
        loop="loop"
        controlsList="nodownload"
        id="myvideo02"
        v-if="'Web' == state.appType"
      >
        <source :src="state.jqrMp4UrlV2" type="video/mp4" />
      </video>

      <video
        v-else
        :src="state.jqrMp4UrlV2"
        :poster="state.mp4coverImg2"
        loop
        controls
        width="360"
        webkit-playsinline="true"
        preload="true"
        playsinline="true"
        x-webkit-airplay="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint"
        id="h5Video"
      ></video>

      <a href="javascript:void(0);" @click="videoPlayFun" class="bf pc pc-2">
        <div class="bg jqr-play">
          <dd>挚达·自动充电机器人</dd>
          <ol>
            <i class="fa fa-play"></i>
          </ol>
        </div>
      </a>
      <a href="javascript:void(0);" rel="popupre" class="popup bf sj">
        <div class="bg jqr-bg">
          <dd>挚达·自动充电机器人</dd>
          <ol>
            <i class="fa fa-play"></i>
          </ol>
        </div>
      </a>
    </div>
    <div v-if="'02' == state.showJqrMp4Type" class="popupbox" id="popupre">
      <div class="hidden">
        <a
          href="javascript:void(0);"
          v-if="'Android' == state.appType"
          class="popupbox_ifix"
          @click="videoCloseFun"
        ></a>
        <a href="javascript:void(0);" v-else class="popupbox_i"></a>
        <video
          controls
          name="media"
          loop="loop"
          controlsList="nodownload"
          id="myvideo02"
          class="full-width"
          width="100%"
          x5-video-player-type="h5"
        >
          <source :src="state.jqrMp4UrlV2" type="video/mp4" />
        </video>
      </div>
    </div>

    <!-- i_sp -->
    <div
      v-if="'01' == state.showJqrMp4Type"
      class="i_sp"
      style="background-color: #f2f2f2"
    >
      <div class="absolute-sel-div" v-if="'Web' == state.appType">
        <div class="absolute-sel-child-div">
          <i @click="updateSelJumpType" class="fa fa-step-backward"></i>
        </div>
        <div class="absolute-sel-child-div">
          <i @click="updateSelJumpType" class="fa fa-step-forward"></i>
        </div>
      </div>

      <!-- <div class="absolute-sel-div mobile-sel-div" v-else>
        <div class="absolute-sel-child-div">
          <i @click="updateSelJumpType" class="fa fa-step-backward"></i>
        </div>
        <div class="absolute-sel-child-div">
          <i @click="updateSelJumpType" class="fa fa-step-forward"></i>
        </div>
      </div> -->
      <video
        poster="https://img.cdn.wxzhida.cn/shzd-web-site/images/sp_banner.jpg"
        controls
        name="media"
        loop="loop"
        controlsList="nodownload"
        id="myvideo"
        v-if="'Web' == state.appType"
      >
        <source :src="state.zdMp4UrlV1" type="video/mp4" />
      </video>

      <video
        v-else
        :src="state.zdMp4UrlV1"
        poster="https://img.cdn.wxzhida.cn/shzd-web-site/images/sp_banner.jpg"
        loop
        controls
        width="360"
        webkit-playsinline="true"
        preload="true"
        playsinline="true"
        x-webkit-airplay="true"
        x5-video-player-type="h5"
        x5-video-player-fullscreen="true"
        x5-video-orientation="portraint"
        id="h5Video"
      ></video>

      <a href="javascript:void(0);" @click="videoCloseFun" class="bf pc">
        <div class="bg">
          <dd>充电·懂车·爱生活</dd>
          <ol>
            <i class="fa fa-play"></i>
          </ol>
        </div>
      </a>
      <a href="javascript:void(0);" rel="popupre" class="popup bf sj">
        <div class="bg">
          <dd>充电·懂车·爱生活</dd>
          <ol>
            <i class="fa fa-play"></i>
          </ol>
        </div>
      </a>
    </div>

    <div v-if="'01' == state.showJqrMp4Type" class="popupbox" id="popupre">
      <div class="hidden">
        <a
          href="javascript:void(0);"
          v-if="'Android' == state.appType"
          class="popupbox_ifix"
          @click="videoCloseFun"
        ></a>
        <a href="javascript:void(0);" v-else class="popupbox_i"></a>
        <video
          controls
          name="media"
          loop="loop"
          controlsList="nodownload"
          id="myvideo"
          class="full-width"
          width="100%"
          x5-video-player-type="h5"
        >
          <source :src="state.zdMp4UrlV1" type="video/mp4" />
        </video>
      </div>
    </div>
    <div id="i1">
      <div class="bg"></div>
    </div>
    <!-- 金刚K7 -->
    <div id="i2">
      <div class="i_jgK7">
        <div class="ny">
          <div class="t">
            <dd>开拓者系列</dd>
            <ol>
              <!-- 无畏释生活 -->
            </ol>
            <a @click="$U.jumpToPageName('kaituozhe', '')">了解更多 ></a>
          </div>
          <div class="f">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/i_k7.png"
              class="tp"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- 为全国数十万家庭提供智慧能源服务 -->
    <div id="i3">
      <div class="i_nyfw">
        <div class="margin width1480 hidden">
          <div class="tit">
            于2021.1-2023.9期间，挚达全球已累计出货90万台家用电动汽车充电桩及在中国已累计出货80万台家用电动汽车充电桩。
          </div>
          <div class="sj" id="sj">900000</div>
          <!-- <ul class="nr">
            <li>
                <div class="bg">
                    <dd><img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/i_nyfw1.png" class="p" /> 全国家庭充电服务平台</dd>
                    <dl>优化家电负荷调配,降低家庭电能开销,注重家居环境的舒适度,提高生活质量。</dl>
                </div>
            </li>
            <li>
                <div class="bg">
                    <dd><img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/i_nyfw2.png" class="p" />社区充电运营服务平台</dd>
                    <dl>对区域充电信息统一采集、统一管辖,降低人力成本,实现用电智能调配、信息融合和高效利用。</dl>
                </div>
            </li>
            <li>
                <div class="bg">
                    <dd><img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/i_nyfw3.png" class="p" />家庭智慧能源服务平台</dd>
                    <dl>家庭电站可根据业主意愿采取“自发自用,余额上网”和“全额上网”两种运行模式。</dl>
                </div>
            </li>
        </ul> -->
        </div>
      </div>
    </div>
    <!-- 新闻中心 -->
    <div id="i4" v-if="'Web' == state.appType">
      <div class="margin width1480 hidden i_news">
        <div class="tit">
          <i>新闻中心</i>
          <a @click="$U.jumpToPageName('news', '')">了解更多 ></a>
        </div>
        <div class="flexslider pc_xw">
          <ul class="slides">
            <li>
              <div
                class="bg"
                v-for="news in state.firstNewsList"
                :key="news.title"
              >
                <a @click="jumpToNewsDetail(news.url)">
                  <div class="t">
                    <img :src="news.img" class="news-set-img" />
                  </div>
                  <div class="f">
                    <ol>
                      {{
                        news.date
                      }}
                    </ol>
                    <dd>{{ news.title }}</dd>
                    <dl>{{ news.subtitle }}</dl>
                    <p class="n">阅读详细</p>
                  </div>
                </a>
              </div>
            </li>

            <li>
              <div
                class="bg"
                v-for="news in state.secondNewsList"
                :key="news.title"
              >
                <a @click="jumpToNewsDetail(news.url)">
                  <div class="t">
                    <img :src="news.img" class="news-set-img" />
                  </div>
                  <div class="f">
                    <ol>
                      {{
                        news.date
                      }}
                    </ol>
                    <dd>{{ news.title }}</dd>
                    <dl>{{ news.subtitle }}</dl>
                    <p class="n">阅读详细</p>
                  </div>
                </a>
              </div>
            </li>

            <li>
              <div
                class="bg"
                v-for="news in state.thirdNewsList"
                :key="news.title"
              >
                <a @click="jumpToNewsDetail(news.url)">
                  <div class="t">
                    <img :src="news.img" class="news-set-img" />
                  </div>
                  <div class="f">
                    <ol>
                      {{
                        news.date
                      }}
                    </ol>
                    <dd>{{ news.title }}</dd>
                    <dl>{{ news.subtitle }}</dl>
                    <p class="n">阅读详细</p>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- 新闻中心 -->
    <div id="i4" v-else>
      <div class="margin width1480 hidden i_news">
        <div class="tit">
          <i>新闻中心</i>
          <a @click="$U.jumpToPageName('news', '')">了解更多 ></a>
        </div>
        <div class="flexslider sj_xw h5-news-view">
          <ul class="slides">
            <li>
              <div
                class="bg"
                v-for="news in state.firstNewsListH5"
                :key="news.title"
              >
                <a @click="jumpToNewsDetail(news.url)">
                  <div class="t h5-t-height-od">
                    <img :src="news.img" class="news-set-img" />
                  </div>
                  <div class="f">
                    <ol>
                      {{
                        news.date
                      }}
                    </ol>
                    <dd>{{ news.title }}</dd>
                    <dl>{{ news.subtitle }}</dl>
                    <p class="n">阅读详细</p>
                  </div>
                </a>
              </div>
            </li>

            <li>
              <div
                class="bg"
                v-for="news in state.secondNewsListH5"
                :key="news.title"
              >
                <a @click="jumpToNewsDetail(news.url)">
                  <div class="t h5-t-height-od">
                    <img :src="news.img" class="news-set-img" />
                  </div>
                  <div class="f">
                    <ol>
                      {{
                        news.date
                      }}
                    </ol>
                    <dd>{{ news.title }}</dd>
                    <dl>{{ news.subtitle }}</dl>
                    <p class="n">阅读详细</p>
                  </div>
                </a>
              </div>
            </li>

            <li>
              <div
                class="bg"
                v-for="news in state.thirdNewsListH5"
                :key="news.title"
              >
                <a @click="jumpToNewsDetail(news.url)">
                  <div class="t h5-t-height-od">
                    <img :src="news.img" class="news-set-img" />
                  </div>
                  <div class="f">
                    <ol>
                      {{
                        news.date
                      }}
                    </ol>
                    <dd>{{ news.title }}</dd>
                    <dl>{{ news.subtitle }}</dl>
                    <p class="n">阅读详细</p>
                  </div>
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 挚达全国 -->
    <div id="i5">
      <div class="i_zdqg">
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images/i_zdqg_l.jpg"
          class="bg l"
        />
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images/i_zdqg_r.jpg"
          class="bg r"
        />
        <i
          class="sj"
          style="
            background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/i_zdqg_r.jpg);
          "
        ></i>
        <div class="margin width1480 hidden">
          <div class="nr">
            <div class="t">
              <dd>挚达</dd>
              <!-- <dl>
                <p>
                  根据弗若斯特沙利文的资料，以往绩记录期间的电动汽车充电器销量计算，我们是全球最大的家用电动汽车充电解决方案提供商。始于为汽车制造商和用户提供高品质电动汽车充电器，我们已开发出由高品质超、增值服务和强大数字平台组成的“三位一体”家用电动汽车充电解决方案。
                </p>
              </dl> -->
              <ol>
                <a @click="$U.jumpToPageName('about', '')">了解挚达 ></a>
              </ol>
            </div>
            <ul class="f">
              <li>
                <dd><b id="i_z1">360</b>个</dd>
                <dl class="margin-right-16">
                  截至2023年9月30日，已覆盖全国超过360个城市。
                </dl>
              </li>
              <li>
                <dd><b id="i_z2">60</b>万</dd>
                <dl class="margin-right-16">
                  于2021.1-2023.9期间，我们完成合计60万次安装及售后服务工作。
                </dl>
              </li>
              <li>
                <dd><b id="i_z3">90</b>万</dd>
                <dl class="margin-right-16">
                  于2021.1-2023.9期间，我们全球已累计出货90万台家用电动汽车充电桩及在中国已累计出货80万台家用电动汽车充电桩。
                </dl>
              </li>
              <li>
                <dd><b id="i_z4">20.5</b>%</dd>
                <dl class="margin-right-16">
                  根据弗若斯特沙利文的资料，于2021.1-2023.9期间，按家用电动汽车充电桩销量计，我们的中国市场占有率达20.5%，全球市场占有率达到12.2%。
                </dl>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- 桩到家 -->
    <div id="i6">
      <div class="margin width1480 hidden i_zdj">
        <div
          class="hidden l"
          style="
            background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/i_zdj1.jpg);
          "
        >
          <!-- <div class="bg">
            <dd>挚达APP</dd>
            <dl>
              <p class="pg">
                IOS
                <i
                  ><img
                    src="https://img.cdn.wxzhida.cn/shzd-web-site/images/sj_ewm.png"
                /></i>
              </p>
              <p class="az">
                Android
                <i
                  ><img
                    src="https://img.cdn.wxzhida.cn/shzd-web-site/images/sj_ewm.png"
                /></i>
              </p>
            </dl>
          </div> -->
        </div>
        <div class="hidden r">
          <div
            class="t"
            style="
              background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/i_zdj2.jpg);
            "
          >
            <dd>服务中心</dd>
            <dl>
              在全国范围内拥有超过140家下属安装服务商,为全国多家车企、经销商、运营商提供专业的充电桩安装落地服务
            </dl>
          </div>
          <div
            class="f"
            style="
              background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/i_zdj3.jpg);
            "
          >
            <dd>可持续发展</dd>
            <dl>专精特新“小巨人”企业</dl>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from "vue";
import config from "../../libs/config";

export default {
  name: "Index",
  components: {},
  setup() {
    const { proxy } = getCurrentInstance();

    const state = reactive({
      firstNewsList: config.firstNewsList,
      secondNewsList: config.secondNewsList,
      thirdNewsList: config.thirdNewsList,
      appType: "Web",
      firstNewsListH5: [config.firstNewsList[0]],
      secondNewsListH5: [config.firstNewsList[1]],
      thirdNewsListH5: [config.firstNewsList[2]],
      zdMp4UrlV0: "https://img.cdn.wxzhida.cn/product/files/pile-movie-v1.mp4",
      zdMp4UrlV1:
        "https://img.cdn.wxzhida.cn/shzhida-site/index-mp4/index-mp4-2.0.mp4",
      jqrMp4UrlV2:
        "https://img.cdn.wxzhida.cn/shzhida-site/index-mp4/index-jqr.mp4",
      mp4coverImg0:
        "https://img.cdn.wxzhida.cn/shzd-web-site/images/sp_banner.jpg",
      mp4coverImg1:
        "https://img.cdn.wxzhida.cn/shzhida-site/index-mp4/cover-1.1.jpg",
      mp4coverImg2:
        "https://img.cdn.wxzhida.cn/shzhida-site/index-mp4/cover-2.1.jpg",
      showJqrMp4Type: "01",
    });

    const jumpToNewsDetail = (newsUrl) => {
      if (newsUrl) {
        window.open(newsUrl);
      }
    };

    const videoCloseFun = () => {
      if ("01" == state.showJqrMp4Type) {
        $("#myvideo")[0].pause();
      } else if ("02" == state.showJqrMp4Type) {
        $("#myvideo02")[0].pause();
      }
      $(".pc").css("display", "none");
      $("#fade , .popupbox ").fadeOut();
    };

    const videoPlayFun = () => {
      if ("01" == state.showJqrMp4Type) {
        $("#myvideo")[0].play();
        console.log("videoPlayFun01");
      } else if ("02" == state.showJqrMp4Type) {
        $("#myvideo02")[0].play();
        console.log("videoPlayFun02");
      }
      $(".pc").css("display", "none");
    };

    const updateSelJumpType = (type) => {
      if ("01" == state.showJqrMp4Type) {
        state.showJqrMp4Type = "02";
      } else if ("02" == state.showJqrMp4Type) {
        state.showJqrMp4Type = "01";
      }
    };

    onMounted(() => {
      document.title = "挚达科技股份";
      // 滚动到页面最上方
      window.scrollTo(0, 0);

      var u = navigator.userAgent;
      var isAndroid = u.indexOf("Android") > -1 || u.indexOf("Adr") > -1; //安卓端
      var isIos = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //IOS端
      const innerWidth = window.innerWidth;
      state.appType = "Web";
      if (isAndroid && innerWidth < 450) {
        state.appType = "Android";
      }
      if (isIos && innerWidth < 450) {
        state.appType = "iOS";
      }

      var options2 = {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        decimalPlaces: ".",
        prefix: "",
      };

      var i_z1 = new CountUp("i_z1", 0, 360, 0, 3, options);
      var i_z2 = new CountUp("i_z2", 0, 60, 0, 3, options);
      var i_z3 = new CountUp("i_z3", 0, 90, 0, 3, options);
      var i_z4 = new CountUp("i_z4", 0, 20.5, 1, 3, options2);

      if ("Android" == state.appType) {
        // $(".popupbox_ifix").click(function () {
        //   $(this).hide();
        //   $("#myvideo")[0].pause();
        // });
      } else {
        $(".popupbox_i").click(function () {
          $(this).hide();
          if ("01" == state.showJqrMp4Type) {
            $("#myvideo")[0].pause();
          } else if ("02" == state.showJqrMp4Type) {
            $("#myvideo02")[0].pause();
          }
        });
      }

      $(".bf").click(function () {
        console.log("bf===click");

        $(this).hide();
        if ("01" == state.showJqrMp4Type) {
          $("#myvideo")[0].play();
        } else if ("02" == state.showJqrMp4Type) {
          $("#myvideo02")[0].play();
        }
      });

      // var video = document.getElementById("videoDom");

      // video.addEventListener('myvideo', function () {
      //     //结束
      //     $("#myvideo")[0].pause();
      //   }, false);

      var options = {
        useEasing: true,
        useGrouping: true,
        separator: ",",
        decimal: ".",
        prefix: "",
        suffix: "",
      };
      var demo = new CountUp("sj", 0, 900000, 0, 6, options);
      // TODO
      $(".pc_xw").flexslider({
        animation: "slide",
        slideshowSpeed: 4000,
        start: function (slider) {
          $("body").removeClass("loading");
        },
      });

      setTimeout(() => {
        $(".sj_xw").flexslider({
          animation: "slide",
          slideshowSpeed: 4000,
          start: function () {},
        });
      }, 300);

      // $('.pc_xw').flexslider({
      //   animation: 'slide',
      //   start: function (slider) {
      //     $('body').removeClass('loading')
      //   },
      // })

      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop(); //滚动条距离顶端值
        for (let i = 1; i < 10; i++) {
          if ($("#i" + i) && $("#i" + i).offset()) {
            //加循环
            if ($("#i" + i).offset().top <= wst) {
              //判断滚动条位置
              $("#i" + i).addClass("on x" + i); //给当前导航加c类
            } else {
              $("#i" + i).removeClass("x1");
            }
            //#i2
            if ($("#i2").offset().top <= wst) {
              demo.start();
            }
            //#i4
            if ($("#i4").offset().top <= wst) {
              i_z1.start();
              i_z2.start();
              i_z3.start();
              i_z4.start();
            }
          }
        }
      });

      $(document).ready(function () {
        // Here we will write a function when link click under class popup
        $("a.popup").click(function () {
          // Here we will describe a variable popupid which gets the
          // rel attribute from the clicked link
          var popupid = $(this).attr("rel");

          // Now we need to popup the marked which belongs to the rel attribute
          // Suppose the rel attribute of click link is popuprel then here in below code
          // #popuprel will fadein
          $("#" + popupid).fadeIn();

          // append div with id fade into the bottom of body tag
          // and we allready styled it in our step 2 : CSS
          $("body").append('<div id="fade"></div>');
          $("#fade").css({ filter: "alpha(opacity=80)" }).fadeIn();

          // Now here we need to have our popup box in center of
          // webpage when its fadein. so we add 10px to height and width
          var popuptopmargin = ($("#" + popupid).height() + 10) / 2;
          var popupleftmargin = ($("#" + popupid).width() + 10) / 2;

          // Then using .css function style our popup box for center allignment
          $("#" + popupid).css({
            "margin-top": -popuptopmargin,
            "margin-left": -popupleftmargin,
          });
        });

        // Now define one more function which is used to fadeout the
        // fade layer and popup window as soon as we click on fade layer
        $("#fade").click(function () {
          // Add markup ids of all custom popup box here
          $("#fade , .popupbox ").fadeOut();
          return false;
        });

        if ("Android" == state.appType) {
          $(".popupbox_ifix").click(function () {
            // Add markup ids of all custom popup box here
            $("#fade , .popupbox ").fadeOut();
            return false;
          });
        } else {
          $(".popupbox_i").click(function () {
            // Add markup ids of all custom popup box here
            $("#fade , .popupbox ").fadeOut();
            return false;
          });
        }
      });
    });
    return {
      state,
      jumpToNewsDetail,
      videoCloseFun,
      updateSelJumpType,
      videoPlayFun,
    };
  },
};
</script>
